import http from "../axios/http"

//部门列表
export let getDeptList = (params) => {
    return http.get("dept/list", { params })
}
//部门添加
export let postDeptAdd = (params) => {
    return http.post("dept/add", params)
}
//部门编辑
export let postDeptEdit = (params) => {
    return http.post("dept/edit", params)
}
//部门删除
export let postDeptDel = (params) => {
    return http.post("dept/del", params)
}
