
<template>
  <div class="Bumen page">
    <div class="top">
      <a-input
        placeholder="名称"
        v-model:value="requestParams.username"
      ></a-input>
      <a-select
        placeholder="部门"
        style="width: 180px"
        v-model:value="requestParams.state"
      >
        <a-select-option value="1">客服部</a-select-option>
        <a-select-option value="2">技术部</a-select-option>
      </a-select>
      <a-button type="primary">搜索</a-button>
      <a-button type="primary" @click="addBumen">添加部门</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="{
        current: requestParams.page,
        total: total,
      }"
      rowKey="dept_id"
      @change="changePagination"
    >
      <!-- @change="pageChange" -->

      <template #operation="{ record }">
        <a-button
          size="small"
          style="margin-right: 4px"
          type="primary"
          @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-popconfirm
          title="确定删除吗？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="handleDel(record.dept_id)"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      title="添加部门"
      centered
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="fromRest"
      class="bumen-model"
    >
      <a-form :model="formState">
        <a-form-item label="部门" required>
          <a-input
            placeholder="请输入"
            v-model:value="formState.dname"
            style="width: 250px"
          ></a-input>
        </a-form-item>
        <a-form-item label="备注">
          <a-input
            placeholder="请输入"
            v-model:value="formState.loc"
            style="width: 250px"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
// import { message } from "ant-design-vue";
import {
  getDeptList,
  postDeptAdd,
  postDeptEdit,
  postDeptDel,
} from "../../service/bumen";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "序号",
    dataIndex: "dept_id",
  },
  {
    title: "部门",
    dataIndex: "dname",
  },
  {
    title: "详情",
    dataIndex: "loc",
  },
  {
    title: "用户ID",
    dataIndex: "user_id",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
    fixed: "right",
  },
];
export default {
  name: "Bumen",
  setup() {
    const state = reactive({
      data: [],
      total: undefined,
      visible: false,
      dept_id: undefined,
    });
    const requestParams = reactive({
      page: 1,
      state: undefined,
      company_name: undefined,
      username: undefined,
      limit: 10,
    });
    const changePagination = (e)=>{
      requestParams.page = e.current
      console.log(e);
      init()
    }
    const formState = reactive({
      dname: undefined,
      loc: undefined,
    });
    const fromRest = () => {
      formState.dname = undefined;
      formState.loc = undefined;
      state.dept_id = undefined;
    };
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getDeptList({
        access_token: sessionStorage.getItem("token"),
        ...requestParams,
      });
      console.log(res);
      state.data = res.data.data.dept.list;
      state.total = res.data.data.dept.total;
    };
    const addBumen = () => {
      state.visible = true;
    };
    const handleOk = async () => {
      if (state.dept_id) {
        const res = await postDeptEdit({
          access_token: sessionStorage.getItem("token"),
          ...formState,
          dept_id: state.dept_id,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.visible = false;
          init();
          fromRest();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postDeptAdd({
          access_token: sessionStorage.getItem("token"),
          ...formState,
        });
        console.log(res);
        if (res.data.status === 100000) {
          message.success(res.data.msg);
          state.visible = false;
          init();
          fromRest();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    const handleEdit = (e) => {
      console.log(e);
      state.dept_id = e.dept_id;
      formState.dname = e.dname;
      formState.loc = e.loc;
      state.visible = true;
    };
    const handleDel = async (dept_id) => {
      const res = await postDeptDel({
        access_token: sessionStorage.getItem("token"),
        dept_id,
      });
      console.log(res);
      if (res.data.status === 100000) {
        message.success(res.data.msg);
        init();
      } else {
        message.error(res.data.msg);
      }
    };
    return {
      ...toRefs(state),
      columns,
      requestParams,
      addBumen,
      formState,
      handleOk,
      fromRest,
      handleEdit,
      handleDel,
      changePagination
    };
  },
};
</script>
<style>
.bumen-model .ant-form-item-label {
  min-width: 55px;
}
</style>
<style scoped>
.Bumen {
  padding: 10px;
}
.top {
  margin-bottom: 15px;
}
.top .ant-input,
.top .ant-select {
  width: 180px;
  margin-right: 20px;
}
.top .ant-btn {
  margin-right: 20px;
}
</style>
